import { template as template_86b8e7130eee49d69eee8ef259df7528 } from "@ember/template-compiler";
const FKControlMenuContainer = template_86b8e7130eee49d69eee8ef259df7528(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
